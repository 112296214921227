import axios from 'axios';
import React, { useEffect, useState } from 'react'

import {

    useLocation
} from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const Main = () => {

    let query = useQuery();
    const [PasswordChange, setPasswordChange] = useState(false)
    const [Password, setPassword] = useState("")
    const [ConfirmPassword, setConfirmPassword] = useState("")
    const [PasswordMatches, setPasswordMatches] = useState(false)

    const resetCode = query.get("code")

    console.log("resetCode", resetCode)

    const resetPassword = () => {
        axios.post("https://bolepo.menual.net/auth/reset-password", {
            password: Password,
            passwordConfirmation: ConfirmPassword,
            code: resetCode
        })
            .then(({ data }) => {
                setPasswordChange(true)
            })
            .catch((e) => {
                alert("לא ניתן לשנות את הסיסמא")
            })
    }

    useEffect(() => {

        setPasswordMatches(Password == ConfirmPassword && Password && ConfirmPassword)

    }, [Password, ConfirmPassword])

    if (!resetCode) {
        return <h1>קוד שגוי</h1>
    }

    return (
        <div className="container">
            <div className="row" style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                flexDirection: 'column'
            }}>
                <img src="/images/mabali-logo.png" style={{ width: 200 }} alt="bolepo" />
                <div className="col-lg-6 col-12  ">
                    {!PasswordChange ? <form>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">סיסמא</label>
                            <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" id="password" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">אישור סיסמא</label>
                            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" id="confirmPassword" />
                            {!PasswordMatches ? <span style={{
                                color: 'red'
                            }}>הסיסמא לא תואמת</span> : null}
                        </div>

                        <button disabled={!PasswordMatches} onClick={(e) => {
                            e.preventDefault();
                            resetPassword()
                        }} className="btn btn-primary">עדכון סיסמא</button>
                    </form> : <div>
                        <h1>הסיסמא עודכנה בהצלחה</h1>
                    </div>}
                </div>

            </div>

        </div>
    )
}

export default Main
